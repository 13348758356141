// used for multiple slides e.g cards on the solution's template
import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "../../css/slick-carousel.css";
import { screen } from "../variables/variables";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";

const Wrapper = styled.div`
  @media ${screen.xsmall} {
    padding: ${(props) => (props.isForTwoColumn ? "0" : " 0 40px")};
  }

  .slide-list {
    margin: 0 -20px;

    .slick-list {
      padding: 20px 0;
      @media ${screen.xsmall} {
        padding: 30px 0;
      }

      .slick-track {
        display: flex;
        justify-content: center;

        .slick-slide {
          height: inherit !important;
          margin: 0 20px;
          box-shadow: 0 3px 14px rgba(30, 30, 30, 0.15);
          border-radius: 12px;
          max-width: 450px;
        }
      }
    }

    &__each {
      text-align: center;
      padding: 35px 35px 40px 35px;
      margin: 0 auto;
      @media ${screen.xsmall} {
        padding: 50px 40px;
      }

      .icon-img {
        display: block;
        width: 88px;
        margin: 0 auto 25px auto;
        @media ${screen.xsmall} {
          margin: 0 auto 40px auto;
          width: 100px;
        }
      }

      .subheading {
        font-size: 0.95rem;
        font-weight: 700;
        margin: 0 0 15px 0;
        text-transform: uppercase;
        @media ${screen.xsmall} {
          font-size: 1.1rem;
          margin: 0 0 20px 0;
        }
      }

      .description {
      }
    }

    .slick-dots {
      bottom: -30px;

      li {
        margin: 0 4px;

        button {
          background: #d0cece;
          border-radius: 50%;
          width: 4px;
          height: 4px;
          box-shadow: 0 3px 10px -2px rgba(30, 30, 30, 0.7);
          ::before {
            color: #d0cece;
            opacity: 0;
          }
        }
      }

      .slick-active {
        button {
          background: #009ed0;
          transform: scale(1.35);

          ::before {
            color: #009ed0;
          }
        }
      }
    }

    .arrow {
      display: none;
      @media ${screen.xsmall} {
        display: block;
        color: #d0cece;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 5;
        font-size: 3.5rem;
      }

      &--next {
        right: -40px;
      }

      &--prev {
        left: -40px;
      }
    }
  }
`;

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="arrow arrow--next" role="button" onClick={onClick}>
      <IoMdArrowDropright />
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="arrow arrow--prev" role="button" onClick={onClick}>
      <IoMdArrowDropleft />
    </div>
  );
}

const MultipleSlides = ({ items, slidesToNum }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToNum || 2,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1041,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Wrapper isForTwoColumn={slidesToNum === 2}>
      {items && (
        <Slider className="slide-list" {...settings}>
          {items.map((slide, index) => (
            <div className="slide-list__each" key={index}>
              <img
                className="icon-img"
                alt={slide.icon_image.alt || "Irada icon"}
                src={slide.icon_image.url}
              />
              <h4 className="subheading">{slide.subheading.text}</h4>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: slide.description.html }}
              />
            </div>
          ))}
        </Slider>
      )}
    </Wrapper>
  );
};

export default MultipleSlides;
