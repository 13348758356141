import React, { useState } from "react";
import styled from "styled-components";
import { mainBlack, mainWhite, screen } from "../../variables/variables";
import { motion } from "framer-motion";
import SVGDiagram from "../../../images/svg/Irada-credit-loss-graph-1.svg";
import InnerWrapper from "../../inner-wrapper/inner-wrapper";
import { diagramItems } from "./data";
import IradaIcon from "../../../images/svg/icon-irada.svg";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.175,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: 8 },
  show: { opacity: 1, y: 0 },
};

const Wrapper = styled.section`
  padding: 45px 0 60px 0;
  overflow: hidden;
  @media ${screen.xsmall} {
    padding: 90px 0;
  }

  .heading {
    text-align: center;
    margin: 0 0 90px 0;
  }

  .diagram-wrapper {
    position: relative;
    width: 310px;
    margin: 170px auto 0 auto;
    @media ${screen.xsmall} {
      width: 550px;
      margin: 220px auto 0 auto;
    }
    @media ${screen.small} {
      margin: 0;
    }
    @media ${screen.medium} {
      width: 650px;
    }

    svg {
      #Icon-8 {
        transition: opacity 0.15s ease-out;
        opacity: ${({ currentActive }) => (currentActive === 0 ? 0.2 : 1)};
      }
      #Icon-1 {
        transition: opacity 0.15s ease-out;
        opacity: ${({ currentActive }) => (currentActive === 1 ? 0.2 : 1)};
      }
      #Icon-2 {
        transition: opacity 0.15s ease-out;
        opacity: ${({ currentActive }) => (currentActive === 2 ? 0.2 : 1)};
      }
      #Icon-3 {
        transition: opacity 0.15s ease-out;
        opacity: ${({ currentActive }) => (currentActive === 3 ? 0.2 : 1)};
      }
      #Icon-4 {
        transition: opacity 0.15s ease-out;
        opacity: ${({ currentActive }) => (currentActive === 4 ? 0.2 : 1)};
      }
      #Icon-5 {
        transition: opacity 0.15s ease-out;
        opacity: ${({ currentActive }) => (currentActive === 5 ? 0.2 : 1)};
      }
      #Icon-6 {
        transition: opacity 0.15s ease-out;
        opacity: ${({ currentActive }) => (currentActive === 6 ? 0.2 : 1)};
      }
      #Icon-7 {
        transition: opacity 0.15s ease-out;
        opacity: ${({ currentActive }) => (currentActive === 7 ? 0.2 : 1)};
      }
    }

    .card {
      position: absolute;
      width: 100%;
      top: -124px;
      transform: translateX(-50%);
      left: 50%;
      max-width: 390px;
      @media ${screen.xsmall} {
        top: -145px;
      }
      @media ${screen.small} {
        left: calc(140px + 40vw);
        transform: translateY(-50%);
        top: 50%;
      }
      @media ${screen.medium} {
        left: 750px;
      }
      @media ${screen.large} {
        left: 800px;
      }

      &__item {
        background-color: ${mainWhite};
        box-shadow: 0 4px 14px -2px rgba(30, 30, 30, 0.175);
        display: flex;
        align-items: center;
        border-radius: 99px;
        padding: 13px 11px;
        @media ${screen.small} {
          padding: 13px;
        }

        .txt {
          .subheading {
            font-size: 0.75rem;
            font-weight: 600;
            margin: 0 0 6px 0;
            @media ${screen.xsmall} {
              font-size: 0.85rem;
            }
          }

          ul {
            padding: 0 0 0 18px;

            li {
              list-style-type: circle;
              font-size: 0.75rem;
              line-height: 1.6;
              @media ${screen.xsmall} {
                font-size: 0.85rem;
              }
            }
          }
        }
      }
    }

    .hidden-buttons {
      &__each {
        position: absolute;
        opacity: 0;

        :focus {
          outline: none;
        }

        &--0 {
          top: 32px;
          left: 68px;
          width: 101px;
          height: 52px;
          transform: rotate(70deg);
          @media ${screen.xsmall} {
            top: 59px;
            left: 120px;
            width: 180px;
            height: 88px;
          }
          @media ${screen.medium} {
            top: 66px;
            left: 142px;
            width: 210px;
            height: 110px;
            transform: rotate(70deg);
          }
        }
        &--1 {
          top: 36px;
          left: 150px;
          width: 101px;
          height: 52px;
          transform: rotate(-65deg);
          @media ${screen.xsmall} {
            top: 65px;
            left: 262px;
            width: 180px;
            height: 88px;
            transform: rotate(-65deg);
          }
          @media ${screen.medium} {
            top: 75px;
            left: 312px;
            width: 210px;
            height: 100px;
            transform: rotate(-65deg);
          }
        }
        &--2 {
          top: 95px;
          left: 202px;
          width: 101px;
          height: 52px;
          transform: rotate(162deg);
          @media ${screen.xsmall} {
            top: 175px;
            left: 360px;
            width: 180px;
            height: 88px;
          }
          @media ${screen.medium} {
            top: 214px;
            left: 426px;
            width: 210px;
            height: 98px;
            transform: rotate(170deg);
          }
        }
        &--3 {
          top: 180px;
          left: 195px;
          width: 101px;
          height: 52px;
          transform: rotate(205deg);
          @media ${screen.xsmall} {
            top: 324px;
            left: 344px;
            width: 180px;
            height: 88px;
            transform: rotate(210deg);
          }
          @media ${screen.medium} {
            top: 372px;
            left: 420px;
            width: 204px;
            height: 100px;
            transform: rotate(210deg);
          }
        }
        &--4 {
          top: 228px;
          left: 138px;
          width: 101px;
          height: 52px;
          transform: rotate(72deg);
          @media ${screen.xsmall} {
            top: 407px;
            left: 244px;
            width: 180px;
            height: 88px;
            transform: rotate(74deg);
          }
          @media ${screen.medium} {
            top: 483px;
            left: 288px;
            width: 204px;
            height: 100px;
            transform: rotate(80deg);
          }
        }
        &--5 {
          top: 224px;
          left: 60px;
          width: 101px;
          height: 52px;
          transform: rotate(-64deg);
          @media ${screen.xsmall} {
            top: 397px;
            left: 105px;
            width: 180px;
            height: 88px;
            transform: rotate(-62deg);
          }
          @media ${screen.medium} {
            top: 476px;
            left: 130px;
            width: 204px;
            height: 100px;
            transform: rotate(-64deg);
          }
        }
        &--6 {
          top: 165px;
          left: 7px;
          width: 101px;
          height: 52px;
          transform: rotate(160deg);
          @media ${screen.xsmall} {
            top: 292px;
            left: 10px;
            width: 180px;
            height: 88px;
            transform: rotate(163deg);
          }
          @media ${screen.medium} {
            top: 350px;
            left: 20px;
            width: 204px;
            height: 100px;
            transform: rotate(160deg);
          }
        }
        &--7 {
          top: 83px;
          left: 13px;
          width: 101px;
          height: 52px;
          transform: rotate(-153deg);
          @media ${screen.xsmall} {
            top: 150px;
            left: 20px;
            width: 180px;
            height: 88px;
            transform: rotate(-153deg);
          }
          @media ${screen.medium} {
            top: 185px;
            left: 30px;
            width: 204px;
            height: 90px;
            transform: rotate(-150deg);
          }
        }
      }
    }
  }
`;

const ChangeableIconColour = styled.div`
  svg {
    width: 50px;
    display: block;
    margin: 0 11px 0 0;
    @media ${screen.xsmall} {
      width: 85px;
      margin: 0 20px 0 0;
    }

    .uuid-684da4af-78da-48fc-9b30-0f69bdfdc8c3 {
      fill: ${(props) => (props.fill ? props.fill : mainBlack)};
    }
  }
`;

const DiagramIFRS = () => {
  const [currentActive, setCurrentActive] = useState(1);

  return (
    <Wrapper currentActive={currentActive}>
      <InnerWrapper>
        <h5 className="heading">Auditable IFRS 9 Process</h5>
        <div className="diagram-wrapper">
          <SVGDiagram />

          <div variants={container} className="card">
            {diagramItems.map(
              (each, index) =>
                currentActive === index && (
                  <motion.div
                    initial="hidden"
                    variants={container}
                    animate={currentActive === index ? "show" : "hidden"}
                    className="card__item"
                    key={each.heading + index}
                  >
                    <ChangeableIconColour fill={each.logoColour}>
                      <motion.div variants={item}>
                        <IradaIcon />
                      </motion.div>
                    </ChangeableIconColour>
                    <motion.div className="txt" variants={item}>
                      <h4 className="subheading">{each.heading}</h4>
                      <ul
                        dangerouslySetInnerHTML={{ __html: each.description }}
                      />
                      {/* <ul >
                        <li>
                          <p className="subdescription">{}</p>
                        </li>
                      </ul> */}
                    </motion.div>
                  </motion.div>
                )
            )}
          </div>

          <div className="hidden-buttons">
            {diagramItems.map((item, index) => (
              <div
                className={`hidden-buttons__each hidden-buttons__each--${index}`}
                key={index}
                onClick={() => setCurrentActive(index)}
                onMouseEnter={() => setCurrentActive(index)}
                aria-hidden="true"
              >
                {index}
              </div>
            ))}
          </div>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default DiagramIFRS;
