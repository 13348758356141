export const diagramItems = [
  {
    heading: "Expected Credit Loss (ECL)",
    description: "<li>Accounts staging</li><li>Restructures</li>",
    logoColour: "#A592E6",
  },
  {
    heading: "Governance",
    description:
      "<li>Group policy aligned</li><li>Definitions (cure, write-off, etc.)</li><li>Judgement applied</li>",
    logoColour: "#3C3C3C",
  },
  {
    heading: "Data",
    description:
      "<li>Monthly snapshots</li><li>Segmentation</li><li>Staging flags</li>",
    logoColour: "#4F9959",
  },
  {
    heading: "Probability of Default (PD)",
    description:
      "<li>PD term structure</li><li>Definitions (technical cures)</li>",
    logoColour: "#249D82",
  },
  {
    heading: "Exposure at Default (EAD)",
    description: "<li>Allowance for change in balance over time</li>",
    logoColour: "#1C9D9B",
  },
  {
    heading: "Loss Given Default (LGD)",
    description:
      "<li>Severity of loss</li><li>Build-up of impairments up to the point of write-off</li>",
    logoColour: "#449CCB",
  },
  {
    heading: "Forward Looking Information (FLI)",
    description: "<li>Source and application of macro-economic variables</li>",
    logoColour: "#93D3E8",
  },
  {
    heading: "Effective Interest Rate (EIR)",
    description: "<li>Linked to income recognition</li>",
    logoColour: "#9D9934",
  },
];
