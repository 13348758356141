import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import Banner from "../components/banner/banner";
import AnimatedLogo from "../components/animated-logo/animated-logo";
import {
  mainBlack,
  mainWhite,
  whileInVew,
  screen,
} from "../components/variables/variables";
import InnerWrapper from "../components/inner-wrapper/inner-wrapper";
import MultipleSlides from "../components/carousel-slides/multiple-slides";
import CtaGradient from "../components/cta-gradient/cta-gradient";
import DiagramIFRS from "../components/static/diagram-ifrs/diagram-ifrs";
import DiagramMortgageDesktop from "../images/svg/solution-diagram-desktop.svg";
import DiagramMortgageMobile from "../images/svg/Irada-Mortgage-broker-diagram-mobile2.svg";

const Wrapper = styled.div`
  .text-image-slice {
    background-color: ${mainBlack};
    color: ${mainWhite};
    position: relative;
    overflow: hidden;

    .flex-row {
      position: relative;
      z-index: 2;
      padding: 45px 0 55px 0;
      @media ${screen.xsmall} {
        padding: 90px 0;
      }

      @media ${screen.small} {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      .col {
        &--left {
          margin: 0 0 21px 0;
          @media ${screen.small} {
            width: 52%;
            padding: 0 80px 0 0;
            margin: 0;
          }
          @media ${screen.medium} {
            padding: 0 110px 0 0;
          }
        }

        &--right {
          border-radius: 10px;
          margin: 30px 0 0 0;
          @media ${screen.small} {
            width: 48%;
            margin: 0;
          }

          img {
            border-radius: 10px;
          }
        }
      }
    }

    .diagram {
      &--static {
        padding: 0 0 55px 0;
        @media ${screen.xsmall} {
          padding: 0 0 90px 0;
        }

        &::after {
          @media ${screen.xsmall} {
            content: "";
            background-image: linear-gradient(to bottom, ${mainBlack}, #2c9658);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            max-height: 370px;
            z-index: 1;
            height: calc(80px + 15vw);
          }
        }

        .svg {
          position: relative;
          z-index: 2;

          &--desktop {
            display: none;
            @media ${screen.xsmall} {
              display: block;
            }
          }

          &--mobile {
            @media ${screen.xsmall} {
              display: none;
            }
          }
        }
      }
    }
  }

  .text-cards-carousel-slice {
    padding: 45px 0 55px 0;
    @media ${screen.xsmall} {
      padding: 90px 0;
    }

    &--one-col {
      .top-txt {
        max-width: 820px;
        margin: 0 auto;
        text-align: center;
      }

      .cards-wrapper {
        margin: 15px 0 30px 0;
        @media ${screen.xsmall} {
          margin: 60px 0;
        }
      }
    }

    &--two-col {
      .flex-row {
        @media ${screen.small} {
          display: flex;
        }

        .col {
          &--left {
            margin: 0 0 15px 0;
            @media ${screen.xsmall} {
              margin: 0 0 20px 0;
            }
            @media ${screen.small} {
              width: 40%;
              padding: 30px 40px 0 0;
              margin: 0;
            }
            @media ${screen.medium} {
              padding: 30px 60px 0 0;
            }
            @media ${screen.large} {
              padding: 30px 80px 0 0;
            }
          }

          &--right {
            margin: 0 0 20px 0;
            @media ${screen.xsmall} {
              margin: 0;
            }
            @media ${screen.small} {
              width: 60%;
            }
          }
        }
      }
    }
  }

  .text-image-with-list-slice {
    padding: 45px 0;
    @media ${screen.xsmall} {
      padding: 90px 0;
    }

    .top-txt {
      max-width: 820px;
      margin: 0 auto;
      text-align: center;
    }

    .flex-row {
      margin: 30px 0 0 0;
      @media ${screen.small} {
        margin: 60px 0 0 0;
        display: flex;
        align-items: center;
      }
      @media ${screen.medium} {
        margin: 90px 0 0 0;
      }

      .col {
        &--left {
          border-radius: 10px;
          box-shadow: 0 8px 14px -2px rgba(30, 30, 30, 0.3);
          margin: 0 0 45px 0;
          @media ${screen.small} {
            width: 47%;
            margin: 0;
          }

          img {
            border-radius: 10px;
          }
        }
      }

      .col {
        &--right {
          @media ${screen.small} {
            padding: 0 0 0 50px;
            width: 53%;
          }

          @media ${screen.medium} {
            padding: 0 0 0 100px;
          }

          .item-list {
            &__each {
              background-color: ${mainWhite};
              box-shadow: 0 4px 14px -2px rgba(30, 30, 30, 0.175);
              display: flex;
              align-items: center;
              margin: 0 0 16px 0;
              border-radius: 99px;
              padding: 13px 11px;
              max-width: 460px;
              @media ${screen.small} {
                margin: 0 0 25px 0;
                padding: 13px;
              }
              @media ${screen.medium} {
                margin: 0 0 40px 0;
              }

              .img {
                width: 50px;
                margin: 0 11px 0 0;
                @media ${screen.xsmall} {
                  width: 85px;
                  margin: 0 20px 0 0;
                }
              }

              .txt {
                .subheading {
                  font-size: 0.75rem;
                  font-weight: 600;
                  margin: 0 0 6px 0;
                  @media ${screen.xsmall} {
                    font-size: 0.85rem;
                  }
                }

                .subdescription {
                  font-size: 0.75rem;
                  white-space: pre-wrap;
                  line-height: 1.6;
                  @media ${screen.xsmall} {
                    font-size: 0.85rem;
                  }
                }
              }

              &:nth-child(odd) {
                margin: 0 0 16px auto;
                @media ${screen.small} {
                  margin: 0 0 25px auto;
                }
                @media ${screen.medium} {
                  margin: 0 0 40px auto;
                }
              }
            }
          }
        }
      }
    }
  }

  .cta {
    margin: 0 0 45px 0;
    @media ${screen.xsmall} {
      margin: 0 0 60px 0;
    }
    @media ${screen.small} {
      margin: 0 0 90px 0;
    }
    .none-position {
        position: relative;
        bottom: 0;
    }
  }
`;

const SingleSolution = ({ data }) => {
  const {
    banner_background_image,
    banner_background_image_mobile,
    banner_description,
    body,
    cta_button_label,
    cta_description,
    cta_heading,
    main_title,
    title_tag,
    meta_description,
  } = data.content.data;

  // this specific page has a special static diagram
  const isAnimatedDiargram =
    data.content.id === "1f2bb4b7-eca9-5a94-a74a-0f00ff5550d2";

  const isStaticDiagram =
    data.content.id === "9e8dac55-fdf8-54a8-bec8-15fd07a8002d";

  const renderSlice = body.map((slice) => {
    // return 2 column layout
    if (slice.slice_type === "text_image") {
      return (
        <section className="text-image-slice" key={slice.id}>
          <InnerWrapper>
            <div className="flex-row">
              <motion.div {...whileInVew} className="col col--left">
                <h2 className="heading">{slice.primary.heading.text}</h2>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: slice.primary.description.html,
                  }}
                />
              </motion.div>
              <motion.div {...whileInVew} className="col col--right">
                <GatsbyImage
                  alt={
                    slice.primary.featured_image.alt ||
                    `${main_title.text} in action`
                  }
                  image={slice.primary.featured_image.gatsbyImageData}
                />
              </motion.div>
            </div>
            {isStaticDiagram && (
              <motion.div {...whileInVew} className="diagram diagram--static">
                <div className="svg svg--desktop">
                  <DiagramMortgageDesktop />
                </div>

                <div className="svg svg--mobile">
                  <DiagramMortgageMobile />
                </div>
              </motion.div>
            )}
          </InnerWrapper>

          <AnimatedLogo />
        </section>
      );
    }

    // return carousel slides
    if (slice.slice_type === "cards_carousel") {
      return (
        <section
          className={
            slice.primary.is_two_column
              ? "text-cards-carousel-slice text-cards-carousel-slice--two-col"
              : "text-cards-carousel-slice text-cards-carousel-slice--one-col"
          }
          key={slice.id}
        >
          <InnerWrapper>
            {/* if return two columned layout */}
            {slice.primary.is_two_column ? (
              <div className="flex-row">
                <motion.div {...whileInVew} className="col col--left">
                  <h3 className="heading">{slice.primary.heading.text}</h3>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: slice.primary.description.html,
                    }}
                  />
                </motion.div>

                <motion.div {...whileInVew} className="col col--right">
                  {/* return carousel slides only if not empty */}
                  {slice.items.length !== 0 && (
                    <div className="cards-wrapper">
                      <MultipleSlides items={slice.items} slidesToNum={2} />
                    </div>
                  )}
                </motion.div>
              </div>
            ) : (
              // else return one columned layout
              <>
                <motion.div {...whileInVew} className="top-txt">
                  <h3 className="heading">{slice.primary.heading.text}</h3>
                  {/* only return description if not empty - html tag can still be returned even it's empty - the use .length for better confirmation */}
                  {slice.primary.description.html.length > 10 && (
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: slice.primary.description.html,
                      }}
                    />
                  )}
                </motion.div>
                {/* return carousel slides only if not empty */}
                {slice.items.length !== 0 && (
                  <motion.div {...whileInVew} className="cards-wrapper">
                    <MultipleSlides
                      items={slice.items}
                      slidesToNum={
                        slice.items.length > 3 ? 3 : slice.items.length
                      }
                    />
                  </motion.div>
                )}
              </>
            )}
          </InnerWrapper>
        </section>
      );
    }

    // return 2 column layout with stylize list
    if (slice.slice_type === "image_and_list") {
      return (
        <section className="text-image-with-list-slice" key={slice.id}>
          <InnerWrapper>
            <motion.div {...whileInVew} className="top-txt">
              <h3 className="heading">{slice.primary.heading.text}</h3>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: slice.primary.description.html,
                }}
              />
            </motion.div>

            <div className="flex-row">
              <motion.div {...whileInVew} className="col col--left">
                <GatsbyImage
                  alt={
                    slice.primary.featured_image.alt ||
                    `${main_title.text} in action`
                  }
                  image={slice.primary.featured_image.gatsbyImageData}
                />
              </motion.div>
              <motion.div {...whileInVew} className="col col--right">
                <ul className="item-list">
                  {slice.items.map((each, index) => (
                    <li className="item-list__each" key={index}>
                      <div className="img">
                        <img
                          alt={each.icon_image.alt || "Icon"}
                          src={each.icon_image.url}
                        />
                      </div>
                      <div className="txt">
                        <h4 className="subheading">{each.subheading.text}</h4>
                        <p className="subdescription">{each.subdescription}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </motion.div>
            </div>
          </InnerWrapper>
        </section>
      );
    }
  });

  return (
    <Layout>
      <SEO
        title={title_tag || main_title.text}
        description={meta_description}
        image={banner_background_image.thumbnails.og.url}
      />
      <Wrapper id="single-project">
        <Banner
          banner_background_image={banner_background_image}
          banner_background_image_mobile={banner_background_image_mobile}
          banner_title={main_title}
          banner_description={banner_description}
        />
        {renderSlice}

        {/* currently this section only available in one specific solution page */}
        {isAnimatedDiargram && <DiagramIFRS />}

        <section className="cta">
          <CtaGradient
            cta_heading={cta_heading}
            cta_description={cta_description}
            cta_button_label={cta_button_label}
            className={'none-position'}
          />
        </section>
      </Wrapper>
    </Layout>
  );
};

export default SingleSolution;

export const dataQuery = graphql`
  query ($id: String!) {
    content: prismicSolutionPageTemplate(id: { eq: $id }) {
      id
      data {
        banner_background_image_mobile {
          url
        }
        banner_background_image {
          url
          thumbnails {
            og {
              url
            }
          }
        }
        banner_description {
          html
        }
        body {
          ... on PrismicSolutionPageTemplateDataBodyCardsCarousel {
            id
            slice_type
            items {
              description {
                html
              }
              icon_image {
                alt
                url
              }
              subheading {
                text
              }
            }
            primary {
              description {
                html
              }
              heading {
                text
              }
              is_two_column
            }
          }
          ... on PrismicSolutionPageTemplateDataBodyImageAndList {
            id
            slice_type
            items {
              subdescription
              subheading {
                text
              }
              icon_image {
                alt
                url
              }
            }
            primary {
              description {
                html
              }
              featured_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
              heading {
                text
              }
            }
          }
          ... on PrismicSolutionPageTemplateDataBodyTextImage {
            id
            slice_type
            primary {
              description {
                html
              }
              featured_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
              heading {
                text
              }
            }
          }
        }
        cta_button_label
        cta_description {
          html
        }
        cta_heading {
          text
        }
        main_title {
          text
        }
        meta_description
        title_tag
      }
    }
  }
`;